import { authHeader } from "../auth/authHeader";
import axios from "axios";
import { helpFunc } from "../helpers/helpFunction";
import { userService } from "./user.services";

const ERROR_MESSAGES = {
  save: "Chyba při ukládání dat",
  load: "Chyb při načítání dat",
};

const getPatient = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/patients/${id}/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getCheckin = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/checkins/${id}/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const postNewPatient = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/patients/",
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const patchPatient = async (id, data) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + `/patients/${id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

//accept key and value for filter
const getPatientsFilter = async (params = {}, url) => {
  const options = authHeader();
  try {
    let response;
    if (url) {
      response = await axios.get(url, { ...options });
      return response.data;
    }
    response = await axios.get(process.env.REACT_APP_ROOT_API + "/patients/", {
      ...options,
      params: { ...params },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

let cancel;
const liveSearch = async (params = {}, endPoint = "") => {
  let options = authHeader();
  //uncomenting this line will cause limitation of diagnose search only on diagnosis from clinical pharmacology
  // if (endPoint === 'diagnoses') params = {...params, clinical_pharmacology: true }
  let query = `${process.env.REACT_APP_ROOT_API}/${endPoint}/`;

  if (cancel) {
    cancel.cancel();
  }
  cancel = axios.CancelToken.source();
  try {
    return await axios.get(query, {
      ...options,
      cancelToken: cancel.token,
      params: { ...params },
    });
  } catch (error) {
    return error;
  }
};

const getDrug = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/drugs/${id}`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getDrugs = async () => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/drugs/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getBodyConstitutions = async () => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/body-constitutions/`,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getDiagnose = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/diagnoses/${id}`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const postDiagnoses = async (data, endPoint, id) => {
  const options = authHeader("POST");
  let requests = [];
  try {
    data.forEach((diagnose) => {
      if (diagnose.value === null || diagnose.value === "") return;
      diagnose = {
        diagnosis: diagnose.value,
        [endPoint === "checkin-diagnosis" ? "check_in" : "risk_drug_history"]:
          id,
        drugs: helpFunc.objToArrApi(diagnose.drugs),
      };
      requests.push(
        axios.post(process.env.REACT_APP_ROOT_API + `/${endPoint}/`, diagnose, {
          ...options,
        })
      );
    });

    const [...responses] = await axios.all(requests);
    return responses;
  } catch (error) {
    return handleError(error);
  }
};

const patchDiagnoses = async (data, endPoint, diagnoses, id) => {
  let options = authHeader("DELETE");
  let requests = [];
  try {
    if (diagnoses.length > 0) {
      diagnoses.forEach((diagnose) => {
        if (
          diagnose !== null &&
          diagnose !== undefined &&
          diagnose.id !== undefined
        ) {
          requests.push(
            axios.delete(
              process.env.REACT_APP_ROOT_API + `/${endPoint}/${diagnose.id}/`,
              { ...options }
            )
          );
        }
      });
      const [...resposne] = await axios.all(requests);
    }
  } catch (error) {
    return handleError(error);
  }

  try {
    const responses = await postDiagnoses(data, endPoint, id);
    return responses;
  } catch (error) {
    return handleError(error);
  }
};

const postCheckIn = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/checkins/",
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const patchCheckIn = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + `/checkins/${id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const postPharmaPlan = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/pharmacological-plans/",
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const patchPharmaPlan = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + `/pharmacological-plans/${id}/`,
      data,
      { ...options }
    );
    return response.data;
  } catch (error) {
    error.message = ERROR_MESSAGES.save;
    return handleError(error);
  }
};

const getPharmaPlan = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/pharmacological-plans/${id}/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getTags = async (params = {}) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + "/tags/",
      { ...options, params: { ...params } }
    );
    return response.data;
  } catch (error) {
    error.message = ERROR_MESSAGES.load;
    return handleError(error);
  }
};

const patchTags = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + `/tags/${id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

// Create new tag, function return created tag's ID
// data: {name: string, description: string}
const postTags = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + `/tags/`,
      data,
      { ...options }
    );
    return response.data.id;
  } catch (error) {
    return handleError(error);
  }
};

const getPlanVerifications = async (id) => {
  const options = authHeader();
  const params = { pharmacological_plan: id };
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/pharmacological-plan-verifications/`,
      { ...options, params: params }
    );
    return response.data.results;
  } catch (error) {
    error.message = ERROR_MESSAGES.load;
    return handleError(error);
  }
};

const postPlanVerifications = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/pharmacological-plan-verifications/",
      data,
      { ...options }
    );
    return response.data;
  } catch (error) {
    error.message = ERROR_MESSAGES.save;
    return handleError(error);
  }
};

const patchPlanVerifications = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-plan-verifications/${id}/`,
      data,
      { ...options }
    );
    return response.data;
  } catch (error) {
    error.message = "Chyba při ukládání aktualizace plánu";
    return handleError(error);
  }
};

const deletePlanVerifications = async (id) => {
  const options = authHeader("DELETE");
  try {
    const response = await axios.delete(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-plan-verifications/${id}/`,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getPlanTexts = async (id) => {
  const options = authHeader();
  const params = { pharmacological_plan: id };
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/pharmacological-plan-texts/`,
      { ...options, params: params }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const postPlanTexts = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/pharmacological-plan-texts/",
      data,
      { ...options }
    );
    return response.data;
  } catch (error) {
    error.message = ERROR_MESSAGES.save;
    return handleError(error);
  }
};

const patchPlanTexts = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + `/pharmacological-plan-texts/${id}/`,
      data,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const deletePlanTexts = async (id) => {
  const options = authHeader("DELETE");
  try {
    const response = await axios.delete(
      process.env.REACT_APP_ROOT_API + `/pharmacological-plan-texts/${id}/`,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getRecommendations = async (id) => {
  const options = authHeader();
  const params = { care: id };
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/pharmacological-plan-recommendations/`,
      { ...options, params: params }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const postRecommendation = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/pharmacological-plan-recommendations/",
      data,
      { ...options }
    );
    return response.data;
  } catch (error) {
    error.message = ERROR_MESSAGES.save;
    return handleError(error);
  }
};

const patchRecommendation = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-plan-recommendations/${id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const deleteRecommendation = async (id) => {
  const options = authHeader("DELETE");
  try {
    const response = await axios.delete(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-plan-recommendations/${id}/`,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getRecommendationsDischarge = async (id) => {
  const options = authHeader();
  const params = { care: id };
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-plan-discharge_recommendations/`,
      { ...options, params: params }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const postRecommendationsDischarge = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API +
        "/pharmacological-plan-discharge_recommendations/",
      data,
      { ...options }
    );
    return response.data;
  } catch (error) {
    error.message = ERROR_MESSAGES.save;
    return handleError(error);
  }
};

const patchRecommendationsDischarge = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-plan-discharge_recommendations/${id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const deleteRecommendationsDischarge = async (id) => {
  const options = authHeader("DELETE");
  try {
    const response = await axios.delete(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-plan-discharge_recommendations/${id}/`,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getRiskDrugHistory = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/risk-drug-histories/${id}/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const postRiskDrugHistory = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/risk-drug-histories/",
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const postPlanEvaluation = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/pharmacological-plan-evaluations/",
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    error.message = ERROR_MESSAGES.save;
    return handleError(error);
  }
};

const patchPlanEvaluation = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-plan-evaluations/${id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const patchRiskDrugHistory = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + `/risk-drug-histories/${id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getRiskDrugHistoryComments = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API +
        `/risk-drug-history-comments/?risk_drug_history=${id}`,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const postRiskDrugHistoryComment = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/risk-drug-history-comments/",
      data,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const deleteRiskDrugHistoryComment = async (id) => {
  const options = authHeader("DELETE");
  try {
    const response = await axios.delete(
      process.env.REACT_APP_ROOT_API + `/risk-drug-history-comments/${id}`,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const getEvaluationList = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-evaluations/?care=${id}`,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const getEvaluation = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/pharmacological-evaluations/${id}/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const postEvaluation = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/pharmacological-evaluations/",
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const patchEvaluation = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + `/pharmacological-evaluations/${id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const deleteEvaluation = async (id) => {
  const options = authHeader("DELETE");
  try {
    const resposne = await axios.delete(
      process.env.REACT_APP_ROOT_API + `/pharmacological-evaluations/${id}/`,
      { ...options }
    );
    return resposne;
  } catch (error) {
    return handleError(error);
  }
};

const getEvaluationComments = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-evaluation-comments/?pharmacological_evaluation=${id}`,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};
const postEvaluationComment = async (data) => {
  const options = authHeader("POST");
  try {
    const response = axios.post(
      process.env.REACT_APP_ROOT_API + "/pharmacological-evaluation-comments/",
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const deleteEvaluationComment = async (id) => {
  const options = authHeader("DELETE");
  try {
    const response = axios.delete(
      process.env.REACT_APP_ROOT_API +
        `/pharmacological-evaluation-comments/${id}`,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getPatientInformation = async (patientId) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API +
        `/patient-informations/?patient=${patientId}`,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const postPatientInformation = async (data) => {
  let options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + `/patient-informations/`,
      data,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const postPatientRepresentativess = async (data) => {
  let options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + `/patient-representativess/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const deletePatientRepresentativess = async (id) => {
  let options = authHeader("DELETE");
  try {
    const response = await axios.delete(
      process.env.REACT_APP_ROOT_API + `/patient-representativess/${id}`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const patchPatientInformation = async (data, id) => {
  let options = authHeader("POST");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + `/patient-informations/${id}/`,
      data,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const deletePatientInformation = async (id) => {
  const options = authHeader("DELETE");
  try {
    const response = await axios.delete(
      process.env.REACT_APP_ROOT_API + `/patient-informations/${id}/`,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const getProcedures = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/cares/${id}/procedures/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getPatientCategories = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/patient_categories/`,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const getRequisitionsByCareId = async (careId) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/requisitions/?care=${careId}`,
      { ...options }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const getRequisitionsById = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/requisitions/${id}/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const patchRequisitions = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + `/requisitions/${id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getRequisitionsAdverseEffect = async (requisitionId) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API +
        `/requisitions/${requisitionId}/adverse-effect/`,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const postRequisitionsAdverseEffect = async (data, requisitionId) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API +
        `/requisitions/${requisitionId}/adverse-effect/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const patchRequisitionsAdverseEffect = async (data, requisitionId) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API +
        `/requisitions/${requisitionId}/adverse-effect/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getRequisitionsAdverseEffectDrugs = async (requisitionId) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API +
        `/requisitions/${requisitionId}/adverse-effect/drugs/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const postRequisitionsAdverseEffectDrugs = async (data, requisitionId) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API +
        `/requisitions/${requisitionId}/adverse-effect/drugs/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const patchRequisitionsAdverseEffectDrugs = async (data, requisitionId) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API +
        `/requisitions/${requisitionId}/adverse-effect/drugs/${data.id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const deleteRequisitionsAdverseEffectDrugs = async (data, requisitionId) => {
  const options = authHeader("DELETE");
  try {
    const response = await axios.delete(
      process.env.REACT_APP_ROOT_API +
        `/requisitions/${requisitionId}/adverse-effect/drugs/${data.id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getRequisitionsAdverseEffectSimultaneous = async (requisitionId) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API +
        `/requisitions/${requisitionId}/adverse-effect/simultaneous-drugs/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const postRequisitionsAdverseEffectSimultaneous = async (
  data,
  requisitionId
) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API +
        `/requisitions/${requisitionId}/adverse-effect/simultaneous-drugs/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const patchRequisitionsAdverseEffectSimultaneous = async (
  data,
  requisitionId
) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API +
        `/requisitions/${requisitionId}/adverse-effect/simultaneous-drugs/${data.id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const getRequisitionsType = async (params) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/requisition-types/`,
      { ...options, params: params }
    );
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

const getWeightHistory = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/patients/${id}/weight-history/`,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

function handleError(error) {
  if (error.response && error.response.status === 401) {
    userService.logout();
    window.location.replace("/signin");
  }

  return Promise.reject(error);
}

export const patientService = {
  liveSearch,
  postNewPatient,
  patchPatient,
  getPatientsFilter,
  getDiagnose,
  postDiagnoses,
  patchDiagnoses,
  getDrug,
  getDrugs,
  getPatient,
  getCheckin,
  getTags,
  patchTags,
  postTags,
  postCheckIn,
  postPharmaPlan,
  patchPharmaPlan,
  getPharmaPlan,
  getPlanVerifications,
  postPlanVerifications,
  patchPlanVerifications,
  deletePlanVerifications,
  getPlanTexts,
  postPlanTexts,
  patchPlanTexts,
  deletePlanTexts,
  getRecommendations,
  postRecommendation,
  patchRecommendation,
  deleteRecommendation,
  getRecommendationsDischarge,
  postRecommendationsDischarge,
  patchRecommendationsDischarge,
  deleteRecommendationsDischarge,
  postPlanEvaluation,
  patchPlanEvaluation,
  getRiskDrugHistory,
  postRiskDrugHistory,
  patchRiskDrugHistory,
  getRiskDrugHistoryComments,
  postRiskDrugHistoryComment,
  deleteRiskDrugHistoryComment,
  getEvaluationList,
  getEvaluation,
  postEvaluation,
  patchEvaluation,
  deleteEvaluation,
  getEvaluationComments,
  postEvaluationComment,
  deleteEvaluationComment,
  patchCheckIn,
  getPatientInformation,
  deletePatientInformation,
  postPatientInformation,
  patchPatientInformation,
  getProcedures,
  getPatientCategories,
  getRequisitionsByCareId,
  getRequisitionsById,
  patchRequisitions,
  getRequisitionsType,
  postRequisitionsAdverseEffectSimultaneous,
  postRequisitionsAdverseEffectDrugs,
  postRequisitionsAdverseEffect,
  getRequisitionsAdverseEffectSimultaneous,
  getRequisitionsAdverseEffectDrugs,
  getRequisitionsAdverseEffect,
  patchRequisitionsAdverseEffectSimultaneous,
  patchRequisitionsAdverseEffectDrugs,
  patchRequisitionsAdverseEffect,
  deleteRequisitionsAdverseEffectDrugs,
  postPatientRepresentativess,
  deletePatientRepresentativess,
  getWeightHistory,
  getBodyConstitutions,
};
